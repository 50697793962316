import React, { useContext } from 'react';
import './App.scss';
import {
  Icon,
  Route,
  TopnavContainer,
  Topnav,
  useMedia,
  SkipToContent,
  SkipToContentItem,
} from '@monash/portal-react';
import {
  AppLauncherPad,
  UserMenu,
  portalLink,
  UserInitialsItem,
  UserInitialSection,
  AuthContext,
  ImpersonationContext,
} from '@monash/portal-frontend-common';
import Landing from './components/pages/landing/Landing';
import UserGuide from './components/pages/user-guide/UserGuide';
import TabNav from 'components/ui/tab-nav/TabNav';
import { transformText } from 'utils/fns/transformText';
import userGuideData from './data.js';
import PageContainer from 'components/layouts/page-container/PageContainer';
import PreviewPanel from 'components/ui/preview-panel/PreviewPanel';
import { USER_TYPE } from 'constants/user-type';

const App = () => {
  const { user } = useContext(AuthContext);
  const size = useMedia();
  const { currentUser } = useContext(ImpersonationContext);

  const createAppPaths = () => {
    return userGuideData.map((item) => `/${transformText(item.app)}`);
  };

  // get user type for app launcher
  const userType = currentUser?.is?.MonashCollege
    ? USER_TYPE.COLLEGE
    : USER_TYPE.GENERAL;

  return (
    <>
      <SkipToContent>
        <SkipToContentItem text="Navigation" skipTo="#topNav" />
        <SkipToContentItem text="Content" skipTo="#contentContainer" />
      </SkipToContent>
      <TopnavContainer>
        <Topnav title="User Guide" size={size} menu={false}>
          <div>
            <AppLauncherPad type={userType} />
            <UserMenu getData={!!user?.studentId}>
              <UserInitialSection>
                <UserInitialsItem
                  id="profile"
                  href={portalLink('profile')}
                  text="View my profile"
                  icon={<Icon.Id />}
                />
                <UserInitialsItem
                  id="courseProgress"
                  href={portalLink('profile', 'course-progress')}
                  text="Course progress"
                  icon={<Icon.Ready />}
                />
              </UserInitialSection>
            </UserMenu>
          </div>
        </Topnav>

        <Route to="/" exact={true}>
          <Landing />
        </Route>

        <Route to="/preview">
          <Landing />
        </Route>

        {createAppPaths().map((path, i) => (
          <Route key={i} to={path}>
            <PageContainer>
              <TabNav />
              <UserGuide />
            </PageContainer>
          </Route>
        ))}

        <PreviewPanel />
      </TopnavContainer>
    </>
  );
};

export default App;
